.bg-breadcrumb-container {
	background-color: #888888 !important;
	color: #ffffff !important;
	padding: 0px 10px 5px 10px;
	border-radius: 2px;
	a {
		color: #ffffff !important;
	}
	.breadcrumb-item {
		color: #ffffff !important;
		&::before {
			color: #ffffff !important;
		}
	}
	.col {
		padding-top: 20px;
	}
	.col-auto {
		padding-top: 13px;
		padding-bottom: 0px;
	}
	.btn-group {
		button {
			border: 1px solid #aaaaaa;
		}
	}
}
.pl-3 {
	padding-left: 3.0rem !important;
}
.bg-darkgray-container {
	background-color: #888888 !important;
	color: #ffffff;
}
.bg-lightgray-container {
	background-color: #e9e9e9 !important;
	border: 1px solid #dddddd;
}
.designer-properties {
	.accordion-button {
		&:not(.collapsed) {
			color: #ffffff;
			background-color: #0c63e4;
			box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
			&::after {
				fill: #ffffff;
				filter: invert(7%) saturate(2877%) hue-rotate(346deg) brightness(104%) contrast(97%);
			}
		}
	}
}
.vsblty-card-header {
	background-color: #888888 !important;
	color: #ffffff !important;
	padding: 0px 10px 5px 10px;
	border-radius: 2px;
	.btn-border-white {
		border: 1px solid #aaaaaa;
	}
}
.sidebar {
	.sidebar-content {
		ul.navigation {
			li.sub.open {
				background: #0d6efd !important;
				>a {
					color: #ffffff !important;
				}
				ul.menu-content {
					background: #dddddd !important;
					li.active {
						background: #888888 !important;
						a {
							color: #ffffff !important;
						}
					}
				}
			}
		}
	}
}
.page-breadcrumb.nobreadcrumb {
	.breadcrumb-item {
		+ {
			.breadcrumb-item {
				&::before {
					color: #888888 !important;
				}
			}
		}
	}
}
.vsblty-accordion-header {
	.accordion-button {
		&:not(.collapsed) {
			color: #ffffff;
			background-color: #0c63e4;
			box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
			&::after {
				fill: #ffffff;
				filter: invert(7%) saturate(2877%) hue-rotate(346deg) brightness(104%) contrast(97%);
			}
		}
	}
}

.vsblty-accordion-header-sm {
	.accordion-button {
		padding: 0.5rem 1.25rem;
	}
}

.vsblty-hidden {
	display: none !important;
}

.vsblty-devices-scroll-y {
	overflow-y: scroll;
	height: 50px;
}

.vsblty-ml-3 {
	margin-left: 2rem !important;
}

.vsblty-font-14 {
	font-size: 14px;
	line-height: 14px;
}

.vsblty-col-flex-assets .vsblty-assets-list {
	.col {
		flex: 0 0 0% !important;
	}
	.g-2, .gx-2 {
		--bs-gutter-x: 1.5rem !important;
	}
}

.vsblty-checkbox-13 {
	width: 1.3em;
    height: 1.3em;
	margin-top: 10px;
}

// styles for upload assets
.ngx-file-drop__drop-zone{
	border: 1px dashed #888888 !important;
	padding: 5px !important;
	border-radius: 5px !important;
    height: auto !important;
    margin: auto;
	text-align: center;
	h5, i {
		color: #000000;
	}
}

.ngx-file-drop__drop-zone--over {
    background-color: rgba(0, 0, 0, 0.1) !important;
}

// styles for calendar component
.cal-month-view .cal-day-cell.cal-open {
	background-color: #ededed !important;
}

.cal-week-view .cal-event-container {
	min-height: 17px !important;
}

.cal-week-view .cal-time-events .cal-event {
    line-height: 15px;
}

app-designer .ng-autocomplete.form-control .autocomplete-container {
    width: 100%;
}

.ng-autocomplete.form-control .autocomplete-container .suggestions-container .item a.item-selected {
    cursor: not-allowed !important;
	font-weight: bold;
	font-style: italic;
	background-color: #eeeeee;
}

.autocomplete-container .suggestions-container ul li {
    cursor: not-allowed !important;
}