/* You can add global styles to this file, and also import other style files */
@import 'perfect-scrollbar/css/perfect-scrollbar.css';
@import '~angular-calendar/css/angular-calendar.css';

// fix to make sure the tooltips show even on top of dialogs
.tooltip {
  z-index: 9065 !important; //modals have z-index of 1060 and tooltips have it set to 1000
}

.tooltip-designer {
  max-width: 200px;
  background-color: #000;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 8px;
  position: absolute;
  pointer-events: none;
  z-index: 1000;
  display: block;
  opacity: 0;
  transition: opacity .3s;
  top: 0;
  left: 0;
  opacity: 1;
  box-shadow: 0 7px 15px -5px #0006
}

.tooltip-light.tooltip-shadow {
  box-shadow: 0 5px 15px -5px #0006
}

.tooltip:after {
  content: "";
  position: absolute;
  border-style: solid
}

.tooltip-top:after {
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-color: #000 #0000 #0000
}

.tooltip-bottom:after {
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-color: #0000 #0000 #000
}

.tooltip-left:after {
  top: 50%;
  left: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-color: #0000 #0000 #0000 #000
}

.tooltip-right:after {
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-color: #0000 #000 #0000 #0000
}

.tooltip-light:after {
  display: none
}

.tooltip-light {
  border: 1px solid #0000000f;
  background-color: #fff;
  color: #000
}

.tooltip-light .tooltip-arrow {
  position: absolute;
  width: 10px;
  height: 10px;
  transform: rotate(135deg);
  background-color: #00000012
}

.tooltip-light .tooltip-arrow:after {
  background-color: #fff;
  content: "";
  display: block;
  position: absolute;
  width: 10px;
  height: 10px
}

.tooltip-top.tooltip-light {
  margin-top: -2px
}

.tooltip-top.tooltip-light .tooltip-arrow {
  top: 100%;
  left: 50%;
  margin-top: -4px;
  margin-left: -5px;
  background: linear-gradient(to bottom left, #00000012 50%, #0000 0)
}

.tooltip-top.tooltip-light .tooltip-arrow:after {
  top: 1px;
  right: 1px
}

.tooltip-bottom.tooltip-light .tooltip-arrow {
  bottom: 100%;
  left: 50%;
  margin-bottom: -4px;
  margin-left: -5px;
  background: linear-gradient(to top right, #0000001a 50%, #0000 0)
}

.tooltip-bottom.tooltip-light .tooltip-arrow:after {
  top: -1px;
  right: -1px
}

.tooltip-left.tooltip-light .tooltip-arrow {
  top: 50%;
  left: 100%;
  margin-top: -5px;
  margin-left: -4px;
  background: linear-gradient(to bottom right, #00000012 50%, #0000 0)
}

.tooltip-left.tooltip-light .tooltip-arrow:after {
  top: 1px;
  right: -1px
}

.tooltip-right.tooltip-light .tooltip-arrow {
  top: 50%;
  right: 100%;
  margin-top: -5px;
  margin-right: -4px;
  background: linear-gradient(to top left, #00000012 50%, #0000 0)
}

.tooltip-right.tooltip-light .tooltip-arrow:after {
  top: -1px;
  right: 1px
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 60px;
  z-index: 1;
}

.ng-autocomplete.form-control {
  &,
  .autocomplete-container {
    width: unset;
    height: unset;
    box-shadow: unset;
    font-size: unset;
    box-sizing: unset;
    padding: unset;
    line-height: unset;
  }
  .autocomplete-container {
    .suggestions-container .item a {
      color: var(--bs-dark) !important;
    }
    .input-container input {
      padding: 0.375rem 0.75rem;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      height: unset;
    }
  }
}

.is-invalid .autocomplete-container .input-container input {
  border-color: var(--bs-red) !important;
}
.is-invalid .autocomplete-container .input-container input:focus {
  border-color: var(--bs-red) !important;
  box-shadow: 0 0 0 0.25rem rgb(221 18 38 / 25%) !important;
}

.required:before {
  content: "*";
  color: var(--bs-red);
}

.breadcrumb {
  .breadcrumb-item {
    .form-control,
    .form-select {
      width: auto;
      position: relative;
      top: -8px;
    }
    .ng-autocomplete {
      --bs-gutter-x: 1.5rem;
      --bs-gutter-y: 0;
      display: flex;
      flex-wrap: wrap;
      margin-top: calc(var(--bs-gutter-y) * -1);
      margin-right: calc(var(--bs-gutter-x) / -2);
      margin-left: calc(var(--bs-gutter-x) / -2);
    }
  }
}

.vn-send-to-bak * {
  color: transparent !important;
  border-color: transparent !important;
}

@mixin scrolling {
  &::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }
  &::-webkit-scrollbar-track,
  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  }
}
.scroll-x {
  overflow-x: scroll;
  @include scrolling;
}
.scroll-y {
  overflow-y: scroll;
  @include scrolling;
}
.scroll {
  overflow: scroll;
  @include scrolling;
}

.grid-btn-p-1 {
  button {
    //padding: 0.175rem;
    padding: 0.25rem !important;
  }
}
.grid-btn-p-2 {
  button {
    //padding: 0.175rem;
    padding: 0.5rem !important;
  }
}
.grid-btn-p-3 {
  button {
    //padding: 0.175rem;
    padding: 1rem !important;
  }
}
.grid-btn-p-4 {
  button {
    //padding: 0.175rem;
    padding: 1.5rem !important;
  }
}
.nine-grid-btn {
  .btn-group:nth-child(1) {
    button:nth-child(1) {
      border-top-right-radius: 0px;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
    }
    button:nth-child(2) {
      border-radius: 0px;
    }
    button:nth-child(3) {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
    }
  }
  .btn-group:nth-child(2) {
    button {
      border-radius: 0px;
    }
  }
  .btn-group:nth-child(3) {
    button:nth-child(1) {
      border-top-right-radius: 0px;
      border-top-left-radius: 0px;
      border-bottom-right-radius: 0px;
    }
    button:nth-child(2) {
      border-radius: 0px;
    }
    button:nth-child(3) {
      border-top-right-radius: 0px;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
    }
  }
}
.four-arrows-grid-btn {
  .btn-group:nth-child(1) {
    width: 100%;
    button {
      max-width: calc(100% / 3);
      left: calc(100% / 3);
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
    }
  }
  .btn-group:nth-child(2) {
    button:nth-child(1) {
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
    }
    button:nth-child(2) {
      border-radius: 0px;
    }
    button:nth-child(3) {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
    }
  }
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

ngb-timepicker.form-control {
  border: unset;
  padding: 0;
  input {
    padding: 0.3rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #4c5258;
    background-color: #fff;
    background-clip: padding-box;
    height: 2.45rem; //39px;
  }
}

.disabled {
  cursor: default;
  background-image: none;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  color: #333;
  background-color: #E6E6E6;
}

.hide {
  display: none;
}


mwl-calendar-week-view-event .cal-event {
  padding: 0 !important;
  width: calc(100% + 15px) !important;
}
